<template>
  <section>
    <router-view />
    <van-tabbar route>
      <van-tabbar-item
        replace
        :to="{
          name: 'public.service.audit.order',
        }"
        icon="friends-o"
      >
        考核大厅
      </van-tabbar-item>
      <van-tabbar-item
        :to="{
          name: 'public.service.audit.home'
        }"
        icon="orders-o"
      >
        个人中心
      </van-tabbar-item>
      <van-tabbar-item
        :to="{
          name: 'public.service.audit.edit',
        }"
        icon="chat-o"
      >
        我有话说
      </van-tabbar-item>
      <van-tabbar-item
        :to="{
          name: 'public.service.audit.manage',
        }"
        icon="manager-o"
      >
        审核专区
      </van-tabbar-item>
    </van-tabbar>
  </section>
</template>

<script>
export default {

}
</script>
