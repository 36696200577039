var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('router-view'),_c('van-tabbar',{attrs:{"route":""}},[_c('van-tabbar-item',{attrs:{"replace":"","to":{
        name: 'public.service.audit.order',
      },"icon":"friends-o"}},[_vm._v(" 考核大厅 ")]),_c('van-tabbar-item',{attrs:{"to":{
        name: 'public.service.audit.home'
      },"icon":"orders-o"}},[_vm._v(" 个人中心 ")]),_c('van-tabbar-item',{attrs:{"to":{
        name: 'public.service.audit.edit',
      },"icon":"chat-o"}},[_vm._v(" 我有话说 ")]),_c('van-tabbar-item',{attrs:{"to":{
        name: 'public.service.audit.manage',
      },"icon":"manager-o"}},[_vm._v(" 审核专区 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }